import React from 'react'
import Home from '../pages/home/Home'
import Sobre from '../pages/sobre/Sobre'
import Servicos from '../pages/servicos/Servicos'
import Frota from '../pages/frota/Frota'
import Contato from '../pages/contato/Contato'
import ToggleMenu from '../components/ToggleMenu'

const Content = (props) => {

    const WPP = function(){
        window.open("https://api.whatsapp.com/send?phone=551236322247")
    }

    return(
        <div className="content">
            <div className="toggleMenu">
                <ToggleMenu />
            </div>

            <div className="btTop">
                <button className="whatsButton" onClick={WPP}>
                    <i className="fab fa-whatsapp whats"></i>
                </button>
            </div>
            <Home />
            <Sobre />
            <Servicos />
            <Frota />
            <Contato />
        </div>
    )
}

export default Content

