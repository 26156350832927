import React from 'react'
import './Contato.css'

const Info = (props) => {

    return(
        <div className="dados">
            
            <p><i className="fas fa-phone-volume"></i> <span>(12) 3632-2247 / (12) 3622-8740</span></p>
            <p><i className="fas fa-map-marker-alt"></i> Av Bandeirantes, <span>5750</span> – Lot.Industrial, Taubaté SP, <span>12072-000</span>.</p>
            <p className="emailmob"><i className="far fa-envelope"></i> E-mail: <span>contato@transportadorabarreiro.com.br</span></p>

            <div className="btContato">
                <a href="https://goo.gl/maps/aRmRMmA9eCqWZcSL7" target="_blank" rel="noreferrer"><div className="contatoItens rede1"><i className="fas fa-map-marker-alt"></i></div></a>
                <a href="https://www.facebook.com/pages/Transportadora-Barreiro/921272021275106?ti=as" target="_blank" rel="noreferrer"><div className="contatoItens rede3"><i className="fab fa-facebook-f"></i></div></a>
                <a href="https://www.instagram.com/transportadora.barreiro/" target="_blank" rel="noreferrer"><div className="contatoItens rede2"><i className="fab fa-instagram"></i></div></a></div>
        </div>
    )
}

export default Info