import React from 'react';

const Footer = (props) => {

    return(
        <div className="footer">
            <div className="menuRodape">
                <a href="#Sobre">Sobre</a>
                <i className="fas fa-circle"></i>
                <a href="#Servicos">Serviços</a>
                <i className="fas fa-circle"></i>
                <a href="#Frota">Frota</a>
            </div>
            <div className="dedicatoria">
                <p>A empresa está em processo de adequação à LGPD (Lei Geral de Proteção de Dados), a sua política de privacidade está em construção.</p>
                <p>© <span>2021</span> - Transportadora Barreiro | Seu caminho, nosso destino</p>
                <p>Desenvolvido por <a href="http://www.unixtechti.com.br/" target="_blank" rel="noreferrer">UnixtechTI</a></p>
            </div>
        </div>
    )
}

export default Footer