import React from 'react'
import './Servicos.css'
import Toggle from '../../components/Toggle'

const Servicos = (props) => {

    return(
        <section id="Servicos" className="Servicos">
            <h1>Serviços</h1>
            <div className="servicoCards">
                <div className="servico s1">
                    <Toggle service="service1" titulo="Transporte Rodoviário">
                        <p>Possuímos transporte rodoviário para que possamos transportar com toda eficiência necessária para qualquer lugar, prezando sempre pela qualidade, diversidade, desta forma contribuindo para o desenvolvimento econômico do nosso país.</p>
                    </Toggle>
                </div>
                <div className="servico s2">
                    <Toggle service="service2" titulo="Armazenagem com Segurança">
                        <p>Para cada um de nossos projetos, pensamos em todas as ações de transporte necessárias para que o resultado seja satisfatório. Para isso, contamos com uma estrutura projetada e desenvolvida para que os produtos possam ser armazenados com toda a segurança necessária para sua proteção.</p>
                    </Toggle>
                </div>
            </div>
        </section>
    )
}

export default Servicos