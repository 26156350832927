import React, { useState } from 'react';
import { Collapse, Button } from 'reactstrap';
import './Iso.css'
import IsoImg from '../imgs/iso.png'
import IsoPDF from'../imgs/ISO.pdf'


function Iso(props) {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <div className="isodiv">
      <Button onClick={toggle} style={{ background: 'transparent', border: 'none' }}><img src={IsoImg} alt="Iso9001" className="iso" /></Button>
      <Collapse isOpen={isOpen}>
        <div className="textoIso">
          <p>A ISO <span>9001</span> é um sistema de gestão com o intuito de garantir a otimização de processos, maior agilidade no desenvolvimento de produtos e produção mais ágil a fim de satisfazer os clientes e alcançar o sucesso sustentado.</p>
          <p><a href={IsoPDF} target="_BLANK" rel="noreferrer">Clique aqui para visualizar nosso certificado.</a></p>
        </div>
      </Collapse>
    </div>
  );
}

export default Iso;