import React from 'react'
import './Home.css'
import Video from '../../imgs/video-institucional.mp4'
import Iso from '../../components/Iso'

const Home = (props) => {

    return(
        <section id="Home" className="Home">
            <Iso />
            <video muted autoPlay loop className="video">
                <source src={Video} type="video/mp4" />
            </video>
            <div className="barrahome"></div>
        </section>
    )
}

export default Home