import React from 'react'
import ImgSobre from '../../imgs/carretarender.png'
import ImgSobre2 from '../../imgs/sobre1.png'
import './Sobre.css'

const Sobre = (props) => {

    return(
        <section id="Sobre" className="Sobre">
            <div className="sobrenos">
                <div className="imgsobrenos">
                    <img src={ImgSobre} alt="carreta" />
                </div>
                <div className="sobrenostexto">
                    <h1>Sobre nós</h1>
                    <p>Somos a Transportadora Barreiro, fundada em <span>2011</span>, atuando fortemente no setor industrial!</p>
                    <p>Localizada na cidade de Taubaté/ SP, transportamos para todo o Brasil com agilidade e segurança.</p>
                    <p>Contamos com um time de colaboradores que possuem grande experiência no mercado e garantimos total qualidade suporte e satisfação.</p>
                    <p>Possuímos frota própria para realização do nosso transporte, buscando sempre pela eficiência e excelência na prestação de nossos serviços.</p>
                    <p>Nosso principal objetivo é oferecer um trabalho de alta qualidade, segurança e a melhor relação custo–benefício para o cliente.</p>
                </div>
            </div>
            

            <div className="sobrenos">
            
                <div className="sobrenosvalores">
                    <h1>Missão</h1>
                    <p>
                    Contribuir para o sucesso e satisfação dos nossos clientes através de soluções em logística, transporte, e armazenagem de forma rápida com qualidade e excelência.


                    </p>
                </div>
                <div className="espacommv"></div>
                <div className="sobrenosvalores">
                    <h1>Visão</h1>
                    <p>
                         Ser reconhecida pela excelência da prestação de serviço de transporte rodoviário de carga e se tornar referência em soluções em logística para o mercado atuante, valorizando e superando as expectativas de nossos clientes e colaboradores. 
                    </p>
                </div>
                <div className="espacommv"></div>
                <div className="sobrenosvalores">
                   
                    <h1>Valores</h1>
                   
                    <ul>
                        <li>Responsabilidade com nossos clientes, fornecedores, colaboradores e com a sociedade;</li>
                        <li>Parceria e relações duradouras com o mercado; </li>
                        <li>Comprometimento com o resultado e transparência na administração; </li>
                        <li>Inovação em tecnologia e melhoria constante;</li>
                        <li>Sustentabilidade com o meio ambiente;</li>
                       
                    </ul>
                  
                </div>
            </div>
             





            <div className="itensCard">
                <div className="itemCard">
                    <div className="icocard c1">
                        <i className="fas fa-trophy"></i>
                    </div>
                    <div className="esquerda"></div>
                    <div className="textoCard">
                        <h3>Satisfação</h3>
                        <p>Nosso maior prêmio é a sua satisfação com nosso serviço prestado.</p>
                    </div>
                    <div className="direita"></div>
                </div>
                <div className="itemCard">
                    <div className="icocard c2">
                        <i className="fas fa-truck"></i>
                    </div>
                    <div className="esquerda"></div>
                    <div className="textoCard">
                        <h3>Soluções</h3>
                        <p>Temos uma gama diversificada de caminhões para atender da melhor forma.</p>
                    </div>
                    <div className="direita"></div>
                </div>
                <div className="itemCard">
                    <div className="icocard c3">
                        <i className="fa fa-desktop"></i>
                    </div>
                    <div className="esquerda"></div>
                    <div className="textoCard">
                        <h3>Atendimento</h3>
                        <p>Disponíveis para atender você como preferir, telefone, e-mail, WhatsApp e presencial.</p>
                    </div>
                    <div className="direita"></div>
                </div>
                <div className="itemCard">
                    <div className="icocard c4">
                        <i className="fas fa-globe-americas"></i>
                    </div>
                    <div className="esquerda"></div>
                    <div className="textoCard">
                        <h3>Cobertura</h3>
                        <p>Não importa para onde deseja transportar, nós realizamos o serviço!</p>
                    </div>
                    <div className="direita"></div>
                </div>
            </div>
            <div className="space">
                <div className="space2"></div>
                <div className="space3"></div>
            </div>
        </section>
    )
}

export default Sobre