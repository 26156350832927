import React from 'react';
import Logo from '../imgs/logoTB.png'
import LogoB from '../imgs/logoTBB.png'

const Header = (props) => {

    return(
        <nav className="header">
            <div className="logo">
                <img src={Logo} alt="logo" className="logoa" />
            </div>
            <div className="logob">
                <a href="#Home"><img src={LogoB} alt="logo" className="logob" /></a>
            </div>
            <ul className="menu">
                <div className="menuGrande menuHidden">
                    <a href="#Home"><li className="active">Home</li></a>
                    <a href="#Sobre"><li>Sobre</li></a>
                    <a href="#Servicos"><li>Serviços</li></a>
                    <a href="#Frota"><li>Frota</li></a>
                    <a href="#Contato"><li>Contato</li></a>
                </div>
            </ul>
        </nav>
    )
}

export default Header