import React, { useState } from 'react';
import { Collapse, Button } from 'reactstrap';
import './ToggleMenu.css'

const Example = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <div className="ToggleButton">
      <Button onClick={toggle} style={{ background: "#1c417b", height: "45px", width: "45px" }}>
        <i className="fas fa-bars"></i>
      </Button>
      <Collapse isOpen={isOpen}>
        
            <div className="menuGrande menuToggle" onClick={()=> setIsOpen(false)}>
              <ul>
                <a href="#Home" className="LinkHome"><li>Home</li></a>
                <a href="#Sobre"><li>Sobre</li></a>
                <a href="#Servicos"><li>Servicos</li></a>
                <a href="#Frota"><li>Frota</li></a>
                <a href="#Contato"><li>Contato</li></a>
              </ul>
            </div>
      </Collapse>
    </div>
  );
}

export default Example;