import React from 'react'
import './App.css'
import 'jquery/dist/jquery.min.js'
import '../../node_modules/@fortawesome/fontawesome-free/css/all.css'
import Header from '../layout/header'
import Content from '../layout/content'
import Footer from '../layout/footer'

function App() {

  return (
    <div className="App">
      <Header />
      <Content />
      <Footer />
    </div>
  )
}

export default App;
