import React, { useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import classnames from 'classnames';
import './Frota.css'

const MenuContato = (props) => {
  const [activeTab, setActiveTab] = useState('1');

  const toggle = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  }

  return (
    <section id="Frota" className="Frota">
        <h1>Frota</h1>
      <Nav tabs className="Nav">
        <NavItem className="galeriaFrota">
          <NavLink
            className={classnames({ active: activeTab === '1' })}
            onClick={() => { toggle('1'); }}
          >
            <h3>Utilitário</h3>
          </NavLink>
        </NavItem>
        <NavItem className="galeriaFrota">
          <NavLink
            className={classnames({ active: activeTab === '2' })}
            onClick={() => { toggle('2'); }}
          >
            <h3>¾</h3>
          </NavLink>
        </NavItem>
        <NavItem className="galeriaFrota">
          <NavLink
            className={classnames({ active: activeTab === '3' })}
            onClick={() => { toggle('3'); }}
          >
            <h3>Truck</h3>
          </NavLink>
        </NavItem>
        <NavItem className="galeriaFrota">
          <NavLink
            className={classnames({ active: activeTab === '4' })}
            onClick={() => { toggle('4'); }}
          >
            <h3>Carreta</h3>
          </NavLink>
        </NavItem>
        <NavItem className="galeriaFrota">
          <NavLink
            className={classnames({ active: activeTab === '5' })}
            onClick={() => { toggle('5'); }}
          >
            <h3>Guincho</h3>
          </NavLink>
        </NavItem>
        <NavItem className="galeriaFrota">
          <NavLink
            className={classnames({ active: activeTab === '6' })}
            onClick={() => { toggle('6'); }}
          >
            <h3>Munck</h3>
          </NavLink>
        </NavItem>
        <NavItem className="galeriaFrota">
          <NavLink
            className={classnames({ active: activeTab === '7' })}
            onClick={() => { toggle('7'); }}
          >
            <h3>Silo</h3>
          </NavLink>
        </NavItem>
        <NavItem className="galeriaFrota">
          <NavLink
            className={classnames({ active: activeTab === '8' })}
            onClick={() => { toggle('8'); }}
          >
            <h3>Prancha</h3>
          </NavLink>
        </NavItem>
      </Nav>


      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <Row>
            <Col sm="12">
                <div className="videogaleria videogaleria1">
                </div>
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="2">
          <Row>
            <Col sm="12">
                <div className="videogaleria videogaleria2">
                </div>
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="3">
          <Row>
            <Col sm="12">
                <div className="videogaleria videogaleria3">
                </div>
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="4">
          <Row>
            <Col sm="12">
              <div className="videogaleria videogaleria4">
              </div>
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="5">
          <Row>
            <Col sm="12">
              <div className="videogaleria videogaleria5">
              </div>
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="6">
          <Row>
            <Col sm="12">
                <div className="videogaleria videogaleria6">
                </div>
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="7">
          <Row>
            <Col sm="12">
              <div className="videogaleria videogaleria7">
              </div>
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="8">
          <Row>
            <Col sm="12">
              <div className="videogaleria videogaleria8">
              </div>
            </Col>
          </Row>
        </TabPane>
      </TabContent>
    </section>
  );
}

export default MenuContato;