import React from 'react'
import './Contato.css'
import emailjs from "emailjs-com"

const Contato = (props) => {

    function sendEmail(e){
        e.preventDefault();

        emailjs.sendForm('Transportadora Barreiro', 'template_ta6d0vb', e.target, 'user_5Ajiy3IFx55zlkiw0vymd')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
        e.target.reset()
    }

    return(
        <div className="form">
            <form onSubmit={sendEmail}>
                <label htmlFor="nome" className="label">Nome</label>
                    <input type="text" name="name" />
                
                <label htmlFor="nome" className="label">E-mail</label>
                    <input type="text" name="email" />

                <label htmlFor="nome" className="label">Telefone</label>
                    <input type="text" className="telefone" name="tel" />

                <label htmlFor="nome" className="label">Assunto</label>
                    <input type="text" name="assunto" />
                
                <label htmlFor="nome" className="label">Mensagem</label>
                    <textarea name="message" id="" cols="30" rows="10"></textarea>

                <div className="acoes">
                    <span></span>
                    
                    <button type="submit" className="enviar">
                        ENVIAR
                    </button>
                </div>
            </form>
        </div>
    )
}

export default Contato