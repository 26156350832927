import React from 'react'
import './Contato.css'
import MenuContato from './MenuContato'

const Contato = (props) => {

    return(
        <section id="Contato" className="Contato view">
            <div className="imgcontato"></div>
            <MenuContato />
        </section>
    )
}

export default Contato